import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Logo from '../../../assest/images/logo.png';
import Styles from '../../Styles/Header.module.css';
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { getHeader } from "../../../services/routes.services";
import { useDispatch, useSelector } from 'react-redux';
import { setMenu } from './HeaderSlice';
import { RiMenu3Fill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";

function Header() {
    const dispatch = useDispatch();
    const { menu, isScrolled } = useSelector(state => state.header);
    const [wondowlScroll, setWindowScroll] = useState(false);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [contactForm, setContactForm] = useState(false);
    const [error, setError] = useState(null);
    const [isActive, setIsActive] = useState(false);
    let location = useLocation();
    const activeHandleClick = () => {
        setIsActive(!isActive);
    };
    const closeActiveHandleClick = () => {
        setIsActive(false);
    };
    useEffect(() => {
        window.addEventListener("scroll", () => {
          setWindowScroll(window.scrollY > 100);
        });
      }, []);
    useEffect(() => {
        getHeader()
            .then((res) => {
                dispatch(setMenu(res.data.headers));
            })
            .catch((error) => {
                console.error("Failed to fetch header data", error);
                setError("Failed to load menu.");
            });
    }, [dispatch]);

    const contactFormHandler = useCallback(() => {
        setContactForm(prevState => !prevState);
    }, []);

    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };

    
    return (
        <header className={wondowlScroll ? Styles.fixHeader : "noFixHeader"}>
            <Container>
                <Row>
                    <Col>
                        <div className={Styles.flexHeader}>
                            <div className={Styles.logo}>
                                <Link to="/" title="Ananta">
                                    <img src={Logo} alt="Ananta" onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                </Link>
                            </div>
                            <button type="button" onClick={activeHandleClick} className={Styles.mobToggle}><RiMenu3Fill /></button>
                            <div className={`${isActive ? Styles.active : ""} ${Styles.mainMenu}`}>
                                <button type="button" onClick={closeActiveHandleClick} className={Styles.closeToggle}><IoMdClose /></button>
                                <ul className={Styles.navBar}>
                                    {menu &&
                                        menu.map((item, index) => (
                                            <li key={index} className={location.pathname === item.link ? Styles.active : 'null'}><Link to={item.link} title={item.title}>{item.title}</Link></li>
                                        ))
                                    }
                                </ul>
                                <div className={Styles.contactBtn}>
                                <button type="button" onClick={contactFormHandler} aria-expanded={contactForm}>Contact Ananta <IoIosArrowDown /></button>
                                {contactForm && (
                                    <ul className={Styles.contactDropdown}>
                                        <li><Link to="/" title="Connect">Connect</Link></li>
                                        <li><Link to="/" title="Collaborate">Collaborate</Link></li>
                                    </ul>
                                )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </header>
    );
}

export default Header;
