import React, { useEffect, useState } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from 'react-player/youtube';
import axios from 'axios';
import AOS from "aos";
import "aos/dist/aos.css";
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const CHANNEL_ID = process.env.REACT_APP_YOUTUBE_CHENNAL_ID;

function WatchVideoComponents() {
    const [playing, setPlaying] = useState(null);
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const fetchVideoIds = async () => {
            try {
                const searchApiUrl = `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=10`;
                const searchResponse = await axios.get(searchApiUrl);
                const videoIds = searchResponse.data.items
                    .map((item) => item.id.videoId)
                    .filter(Boolean) // Ensures only valid videoIds are included
                    .join(",");
                const videosApiUrl = `https://www.googleapis.com/youtube/v3/videos?key=${API_KEY}&id=${videoIds}&part=snippet,statistics`;
                const videosResponse = await axios.get(videosApiUrl);
                setVideos(videosResponse.data.items);
            } catch (error) {
                console.error("Error fetching data from YouTube API", error);
            }
        };

        fetchVideoIds();
    }, []);

    const settings = {
        className: "center",
        centerMode: true,
        infinite: false,
        initialSlide: 1,
        centerPadding: "250px",
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                centerPadding: "100px",
              }
            },
            {
              breakpoint: 767,
              settings: {
                centerPadding: "50px",
              }
            },
            {
              breakpoint: 580,
              settings: {
                centerPadding: "0",
              }
            }
          ]
    };

    function truncateText(text, maxLength) {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    }

    const playVideoHandler = (videoId) => {
        setPlaying((current) => (current === videoId ? null : videoId));
    };
    useEffect(() => {
        AOS.init({
          duration: 1000,
          offset: 100,  
          easing: "ease-in-out", 
          once: true,
        });
      }, []);
    return (
        <div className={Styles.watchSec}>
            <Container>
                <Row>
                    <Col>
                        <h3 data-aos="fade-up">
                            Watch <span>Videos</span>
                        </h3>
                    </Col>
                </Row>
            </Container>
            <div className={"watchOwl " + Styles.watchSlider} data-aos="fade-up" data-aos-delay="100">
                <Slider {...settings} className="arrowButton">
                    {videos.map((video) => (
                        <div key={video.id} className={"itemList " + Styles.videoItem}>
                            <figure className={Styles.thumbnailWrapper}>
                                {!playing || playing !== video.id ? (
                                    <div className={Styles.palyOverLap}>
                                        <img src={`https://img.youtube.com/vi/${video.id}/hqdefault.jpg`} alt={video.snippet.title} className={Styles.thumbnail}/>
                                        <button type="button" className={Styles.playButton} onClick={() => playVideoHandler(video.id)}><img src={require("../../assest/images/play_icon.webp")} alt="Play"/></button>
                                        <div className={Styles.videoContent}>
                                            <h4>{video.snippet.title}</h4>
                                            <p dangerouslySetInnerHTML={{__html: truncateText(video.snippet.description, 100),}}></p>
                                        </div>
                                    </div>
                                ) : (
                                    
                                    <ReactPlayer
                                        url={`https://www.youtube.com/watch?v=${video.id}`}
                                        playing={true}
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                    />
                                )}
                            </figure>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default WatchVideoComponents;
