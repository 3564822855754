import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";

function KidsCornerComponents (){
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    useEffect(() => {
        AOS.init({
          duration: 1000,
          offset: 100,  
          easing: "ease-in-out", 
          once: true,
        });
      }, []);
    return(
        <div className={Styles.kidsSec}>
            <div className={Styles.kidsImg}>
                <figure>
                    <img src={require('../../assest/images/kids_corner.jpg')} alt="" onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height}  />
                </figure>
            </div>
            <div className={Styles.kidsContent}>
                <h3 data-aos="fade-up">Kid's <span>Corner</span></h3>
                <p data-aos="fade-up" data-aos-delay="100">Dear Kids <span>You are seen, heard, valued. Your thoughts matter.</span></p>
                <Link to='/session-book' title="Talk to us" className={Styles.pinkBtn + " " + Styles.commonBtn} data-aos="fade-up" data-aos-delay="200">Talk to us <IoIosArrowRoundForward /></Link>
            </div>
        </div>
    )
}

export default KidsCornerComponents;